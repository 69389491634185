import CookieConsent from 'react-cookie-consent';
// Styles are to be compatible with both tailwind and MUI styled pages
function CookieConsentBanner({
  handleAccept,
  handleDecline
}) {
  return (
    <CookieConsent
      overlay
      enableDeclineButton
      onAccept={handleAccept}
      onDecline= {handleDecline}
      buttonText='Accept'
      declineButtonText='Decline'
      style={{padding: '10px', background: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      contentStyle={{flex: '0 0 100%', textAlign: 'center', margin: '10px 10px 0'}}
      buttonStyle={{ background: '#98BEA8', color: '#FFF', padding: '5px 20px', fontSize: '18px', fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif' }}
      declineButtonStyle={{ background: '#98BEA8', color: '#FFF', padding: '5px 20px', fontSize: '18px', fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif' }}
    >
      <p>
        We use cookies for performance and to improve your website experience. <a href='/static/terms.pdf'>Read more</a>.
      </p>
      <style jsx>{`
        p {
          font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
          font-size: 18px;
          color: #000;
          font-weight: 500;
          opacity: 0.87;
        }
        a {
          color: #98BEA8;
          text-decoration: underline;
        }
      `}</style>
    </CookieConsent>
  );
}


export default CookieConsentBanner;
